import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import CameraAlt from "@mui/icons-material/CameraAlt";
import { LoadingButton } from "@mui/lab";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { addBusiness } from "../../redux/actions/businessActions";

const mapState = ({ admin }) => ({
  loading: admin.loading,
});

const EditBusiness = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);
  const params = useParams();
  const [business, setBusiness] = useState({});

  useEffect(() => {
    const docRef = doc(db, "business", params.businessID);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setBusiness({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const redirect = () => navigate("/entreprises");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addBusiness(data, redirect));
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Modifier l'entreprise " />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Nom de l'entreprise"
                placeholder={business.title}
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Adresse e-mail"
                placeholder={business.email}
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="phoneNumber"
                label="Numéro de téléphone"
                placeholder={business.phoneNumber}
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                onChange={handleChange}
                placeholder={business.description}
              />
            </Grid>
            <Grid item xs={4} md={8}></Grid>
            <Grid item md={4}>
              <LoadingButton
                loading={loading}
                variant="contained"
                fullWidth
                type="submit"
                sx={{ color: "white", borderRadius: 10 }}
                disableElevation
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default EditBusiness;
