import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import PeopleIcon from "@mui/icons-material/People";
import PublishIcon from "@mui/icons-material/Publish";
import SchoolIcon from "@mui/icons-material/School";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DomainAddIcon from "@mui/icons-material/DomainAdd";

const iconProps = {
  color: "primary",
};

export const drawerItems = [
  {
    text: "Tableau de bord",
    icon: <DashboardIcon {...iconProps} />,
    path: "/tableau-de-bord",
  },
  {
    text: "Offres d'emploi",
    icon: <MarkunreadMailboxIcon {...iconProps} />,
    path: "/offres",
  },
  {
    text: "Formations",
    icon: <SchoolIcon {...iconProps} />,
    path: "/formations",
  },
  {
    text: "Entreprises",
    icon: <CorporateFareIcon {...iconProps} />,
    path: "/entreprises",
  },
  {
    text: "Candidats",
    icon: <PeopleIcon {...iconProps} />,
    path: "/candidats",
  },
];

export const secondaryListItems = [
  {
    text: "Publier une offre",
    icon: <PublishIcon {...iconProps} />,
    path: "/ajouter-une-offre",
  },
  {
    text: "+ Formation",
    icon: <CollectionsBookmarkIcon {...iconProps} />,
    path: "/ajouter-une-formation",
  },
  {
    text: "+ Entreprise",
    icon: <DomainAddIcon {...iconProps} />,
    path: "/ajouter-une-entreprise",
  },
];
