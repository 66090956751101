import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import CameraAlt from "@mui/icons-material/CameraAlt";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBusiness } from "../../redux/actions/businessActions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const mapState = ({ business }) => ({
  loading: business.loading,
});

const AddBusiness = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const categories = [];
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);

  const handleChange = (e) =>
    setProduct({ ...product, [e.target.name]: e.target.value });

  const loadFile = async (event) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output");
      output.src = reader.result;
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      setProduct({ ...product, image: event.target.files[0] });
    }
  };

  const handleEditorChange = (value) => {
    setProduct({ ...product, description: value });
  };

  const redirect = () => navigate("/entreprises");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addBusiness(product, redirect));
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Ajouter une entreprise " />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Nom de l'entreprise"
                required
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Adresse e-mail"
                required
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="phoneNumber"
                label="Numéro de téléphone"
                required
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={product.description}
                onChange={handleEditorChange}
                style={{ height: 200 }}
              />
            </Grid>
            <Grid item sx={{ marginTop: 5 }} xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="import"
                type="file"
                onChange={loadFile}
                multiple
              />
              <label htmlFor="import">
                <Button
                  startIcon={<CameraAlt />}
                  variant="outlined"
                  component="span"
                >
                  Importer le Logo
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center" }}>
                <img id="output" width={200} />
              </Typography>
            </Grid>
            <Grid item xs={4} md={8}></Grid>
            <Grid item md={4}>
              <LoadingButton
                loading={loading}
                variant="contained"
                fullWidth
                type="submit"
                sx={{ color: "white", borderRadius: 10 }}
                disableElevation
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default AddBusiness;
