import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import Title from "../includes/Title";
import FlexBox from "../includes/FlexBox";
import { H3 } from "../includes/Typography";

const UsersStats = () => {
  return (
    <Fragment>
      <Grid item xs={12} md={4}>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Title>Candidats</Title>
          <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
            <Typography fontSize="14px" color="grey.600">
              Total
            </Typography>
            <H3 my="0px">{0} </H3>
          </FlexBox>
        </Paper>
      </Grid>
    </Fragment>
  );
};

export default UsersStats;
