import React from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Grid } from "@mui/material";
import SearchBar from "../../components/includes/SearchBar";
import { useEffect } from "react";
import { useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

const Formations = () => {
  const [formations, setFormations] = useState(null);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    const colRef = collection(db, "formations");
    const q = query(
      colRef,
      //where("status", "==", "validated"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setFormations(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      formations &&
      formations.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title={"Formations"} />
        </Grid>
        <Grid item xs={12}>
          <SearchBar
            searchFilter={searchFilter}
            placeholder={"Rechercher une formation..."}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ p: 3 }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        direction={"row"}
        spacing={1}
      >
        <Grid sx={{ p: 3 }} item xs={12}>
          <Typography variant="h6">
            {formations && formations.length} formations disponibles
          </Typography>
        </Grid>
        {data &&
          data.map((formation) => (
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt="Formation image"
                  height="200"
                  src={formation.image}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {formation.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formation.description.slice(0, 150)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button color="primary" sx={{ borderRadius: 10 }}>
                    {moment(formation.createdAt).fromNow()}
                  </Button>
                  <IconButton
                    component={"a"}
                    href={"/formations/" + formation.id}
                    variant="default"
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    component={"a"}
                    href={"/formations/modifier/" + formation.id}
                    variant="default"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton variant="default">
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </main>
  );
};

export default Formations;
