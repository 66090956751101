import React from "react";
import { Grid, Paper, Avatar, Typography } from "@mui/material";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import SearchBar from "../../components/includes/SearchBar";
import { useEffect } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";

const Applicants = () => {
  const [applicants, setApplicants] = useState(null);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    const colRef = collection(db, "users");
    const q = query(
      colRef,
      //where("status", "==", "validated"),
      orderBy("displayName", "asc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setApplicants(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      applicants &&
      applicants.filter((item) => {
        const itemData = item.displayName
          ? item.displayName.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  return (
    <main>
      <Grid sx={{ p: 3 }} container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title={"Candidats"} />
        </Grid>
        <Grid item xs={12}>
          <SearchBar
            searchFilter={searchFilter}
            placeholder={"Rechercher un candidat..."}
          />
        </Grid>
      </Grid>
      <Grid
        direction={"row"}
        justifyContent={"center"}
        container
        sx={{ p: 3 }}
        spacing={2}
      >
        {data &&
          data.map((applicant) => (
            <Grid
              sx={{ p: 3, backgroundColor: "white" }}
              spacing={2}
              container
              item
              xs={12}
            >
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                item
                xs={12}
                md={4}
              >
                <Avatar
                  src={applicant && applicant.photoURL}
                  sx={{ width: 100, height: 100 }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="h6">
                  {applicant && applicant.displayName}
                </Typography>
                {applicant && applicant.cv && (
                  <Typography
                    target="_blank"
                    component={"a"}
                    href={applicant && applicant.cv}
                    variant="p"
                    color={"primary"}
                  >
                    Voir le CV
                  </Typography>
                )}
                <Typography color={"text.secondary"}>
                  {applicant && applicant.about
                    ? applicant.about.slice(0, 200) + "..."
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </main>
  );
};

export default Applicants;
