import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

export const addBusiness = (business, redirect) => {
  return (dispatch) => {
    const storage = getStorage();
    const storageRef = ref(storage, "images/" + business.image.name);
    const metadata = {
      contentType: "image/*",
    };

    const colRef = collection(db, "business");
    const uploadTask = uploadBytesResumable(
      storageRef,
      business.image,
      metadata
    );

    dispatch({ type: "ADD_BUSINESS_LOADING", payload: true });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          addDoc(colRef, {
            ...business,
            image: downloadURL,
            createdAt: new Date().getTime(),
          })
            .then(() => {
              dispatch({ type: "ADD_BUSINESS_SUCCESS" });
              console.log("sucess");
              return redirect();
            })
            .catch((err) => {
              console.log(err.message);
              dispatch({ type: "ADD_BUSINESS_ERROR", error: err.message });
            });
        });
      }
    );
  };
};
