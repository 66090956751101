import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import offerReducer from "./offerReducer";
import formationReducer from "./formationReducer";
import businessReducer from "./businessReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  offer: offerReducer,
  formation: formationReducer,
  business: businessReducer,
});
