import { Grid, MenuItem, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editOffer } from "../../redux/actions/offerActions";
import { useEffect } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const mapState = ({ offer }) => ({
  loading: offer.loading,
});

const EditOffer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [offer, setOffer] = useState({});
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);
  const workTime = ["Temps plein", "Temps partiel"];
  const contrat = ["CDI", "CDD", "Stage", "Volontariat", "Intérim"];

  useEffect(() => {
    const docRef = doc(db, "offers", params && params.offerID);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setOffer({ ...snapshot.data() });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const handleEditorChange = (value) => {
    setOffer({ ...offer, description: value });
    //onChange(value); // Trigger a callback with the updated content
  };

  const handleChange = (e) =>
    setOffer({ ...offer, [e.target.name]: e.target.value });

  const redirect = () => navigate("/offres");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editOffer(params.offerID, offer, redirect));
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Modifier cette offre " />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="poste"
                label="Poste"
                placeholder={offer.poste}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="business"
                label="Entreprise"
                placeholder={offer.business}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="workTime"
                label="Temps de travail"
                fullWidth
                select
                onChange={handleChange}
              >
                {workTime &&
                  workTime.map((type) => (
                    <MenuItem value={type}>{type} </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="contratType"
                label="Type de contrat"
                fullWidth
                select
                onChange={handleChange}
              >
                {contrat &&
                  contrat.map((type) => (
                    <MenuItem value={type}>{type} </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={offer.description}
                onChange={handleEditorChange}
                style={{ height: 200 }}
              />
            </Grid>

            <Grid item xs={4} md={8}></Grid>
            <Grid item sx={{ mt: 5 }} md={4}>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                fullWidth
                sx={{ color: "white", borderRadius: 10 }}
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default EditOffer;
