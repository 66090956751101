const initialState = {
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_BUSINESS_LOADING":
      return { ...state, loading: payload };
    case "ADD_BUSINESS_SUCCESS":
      return { ...state, loading: false };
    case "ADD_BUSINESS_ERROR":
      return { ...state, loading: false };

    case "EDIT_BUSINESS_LOADING":
      return { ...state, loading: payload };
    case "EDIT_BUSINESS_SUCCESS":
      return { ...state, loading: false };
    case "EDIT_BUSINESS_ERROR":
      return { ...state, loading: false };

    case "DELETE_BUSINESS_LOADING":
      return { ...state, loading: payload };
    case "DELETE_BUSINESS_SUCCESS":
      return { ...state, loading: false };
    case "DELETE_BUSINESS_ERROR":
      return { ...state, loading: false };
    default:
      return state;
  }
};
