import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import PlaceIcon from "@mui/icons-material/Place";
import DescriptionIcon from "@mui/icons-material/Description";
import HistoryIcon from "@mui/icons-material/History";
import { useEffect } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const OfferDetails = () => {
  const params = useParams();
  const [offer, setOffer] = useState({});

  useEffect(() => {
    const docRef = doc(db, "offers", params && params.offerID);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setOffer({
            ...snapshot.data(),
            id: snapshot.id,
            //description: removeHtmlTags(snapshot.data().description),
          });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Box>
      <Container sx={{ py: 8 }}>
        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid sx={{ px: 3 }} item xs={12}>
            <Typography textAlign={"center"}>
              <img src="/images/logo.png" width={200} />
            </Typography>
          </Grid>
          <Grid sx={{ mt: 3 }} item xs={12}>
            <Typography variant="h6">{offer && offer.title}</Typography>
            <Typography color={"text.secondary"}>
              {offer && offer.business}
            </Typography>
            <Box>
              <Button
                sx={{ color: "black" }}
                startIcon={<PlaceIcon color="primary" />}
              >
                Kinshasa
              </Button>
              <Button
                sx={{ color: "black", mx: 1 }}
                startIcon={<DescriptionIcon color="primary" />}
              >
                {offer && offer.contratType}
              </Button>
              <Button
                sx={{ color: "black" }}
                startIcon={<HistoryIcon color="primary" />}
              >
                {offer && offer.workTime}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid container spacing={2}>
          <Grid item sx={12}>
            <Typography textAlign={"justify"}>
              {offer && offer.description && parse(offer && offer.description)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"}>
              <Button
                sx={{ px: 6, mx: 2, borderRadius: 10 }}
                variant="outlined"
                component="a"
                href={"/offres/modifier/" + offer.id}
              >
                Modifer
              </Button>
              <Button sx={{ px: 6, borderRadius: 10 }} variant="outlined">
                Supprimer
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OfferDetails;
