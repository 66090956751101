import React from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Grid } from "@mui/material";
import { Navigate } from "react-router-dom";
import OffersStats from "../../components/dashboard/OffersStats";
import UsersStats from "../../components/dashboard/UsersStats";
import BusinessStats from "../../components/dashboard/BusinessStats";

const Dashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DashboardPageHeader title={"Tableau de bord"} />
      </Grid>
      <OffersStats />
      <BusinessStats />
      <UsersStats />
    </Grid>
  );
};

export default Dashboard;
