import {
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReceiptIcon from "@mui/icons-material/Receipt";
import moment from "moment";
import "moment/locale/fr";
import { useNavigate } from "react-router-dom";
//import { viewNotification } from "../../redux/actions/notificationActions";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import { useEffect } from "react";
import { useState } from "react";
//import addNotification from 'react-push-notification';
import NotificationsIcon from "@mui/icons-material/Notifications";

const NotificationButton = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useFirestoreConnect([
  //     {
  //         collection: 'orders',
  //         where: [
  //             ['restaurant', '==', auth && auth],
  //             ['view', '==', false]
  //         ],
  //         storeAs: 'unview'
  //     }
  // ])

  //const { unview } = useSelector(mapState);
  const [data, setData] = useState(null);

  const handleNotification = (notification) => {
    // dispatch(viewNotification(notification));
    navigate("/orders/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //   useEffect(() => {
  //     if (unview !== undefined) {
  //       setData(unview);
  //       addNotification({
  //           title: 'Nouvelle commande',
  //           message: 'Vous avez réçu une nouvelle commande!',
  //           theme: 'darkblue',
  //           native: true,
  //           duration: 60000,
  //           vibrate: 20,
  //           silent: false
  //       })
  //     }
  //   }, [unview]);

  return (
    <Fragment>
      <IconButton
        sx={{ mr: 1 }}
        id="notification-button"
        aria-controls={open ? "notification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Badge badgeContent={data && data.length} color="primary">
          <NotificationsIcon color="primary" />
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "notification-button",
        }}
        sx={{ P: 2 }}
      >
        <Typography variant="h6" sx={{ p: 2, fontWeight: "600" }}>
          {data && data.length} Notification
        </Typography>
        {data &&
          data.map((item) => (
            <Fragment>
              <MenuItem onClick={() => handleNotification(item.id)}>
                <ListItemIcon>
                  <TableRestaurantIcon
                    color={item.view ? "inherit" : "primary"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.table}
                  secondary={moment(item.createdAt).fromNow()}
                />
              </MenuItem>
              <Typography></Typography>
              <Divider />
            </Fragment>
          ))}
      </Menu>
    </Fragment>
  );
};

export default NotificationButton;
