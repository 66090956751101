import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import routes from "./routes";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Login from "./views/auth";

function App() {
  const [admin, setAdmin] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAdmin(user.uid);
      }
    });
  }, [admin]);

  if (admin)
    return (
      <Layout>
        <Routes>
          {routes.map((route) => (
            <Route exact path={route.path} element={route.component} />
          ))}
        </Routes>
      </Layout>
    );
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/se-connecter" element={<Login />} />
    </Routes>
  );
}

export default App;
