import React from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Grid, Typography, Box, Button, List, IconButton } from "@mui/material";
import SearchBar from "../../components/includes/SearchBar";
import { useState } from "react";
import { useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { deleteOffer } from "../../redux/actions/offerActions";

const Offers = () => {
  const [offers, setOffers] = useState(null);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const colRef = collection(db, "offers");
    const q = query(
      colRef,
      where("status", "==", "init"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setOffers(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      offers &&
      offers.filter((item) => {
        const itemData = item.poste
          ? item.poste.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  const handleDelete = (offer) => {
    dispatch(deleteOffer(offer));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DashboardPageHeader title={"Offres d'emploi"} />
      </Grid>
      <Grid item xs={12} sx={{ px: 3 }}>
        <SearchBar
          searchFilter={searchFilter}
          placeholder={"Rechercher une offre..."}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{ p: 3 }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        direction={"row"}
        spacing={1}
      >
        <Grid container spacing={1} item xs={12} md={4}>
          <Grid item xs={12} md={12}>
            <Box sx={{ p: 3, backgroundColor: "#fff" }}>
              <Typography>Type de contrat</Typography>
              <List dense>
                <Button sx={{ display: "block" }}>
                  CDD (durée déterminée){" "}
                </Button>
                <Button sx={{ display: "block" }}>
                  CDI (durée indéterminée)
                </Button>
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ p: 3, backgroundColor: "#fff" }}>
              <Typography>Temps de travail</Typography>
              <List dense>
                <Button sx={{ display: "block" }}>Temps plein</Button>
                <Button sx={{ display: "block" }}>Temps partiel</Button>
              </List>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} md={8}>
          <Grid item xs={8}>
            <Typography
              sx={{ backgroundColor: "#fff", p: 1 }}
              textAlign={"center"}
            >
              Offre d'emploi: {search}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{ backgroundColor: "#fff", p: 1 }}
              textAlign={"center"}
            >
              {data && data.length} résultats
            </Typography>
          </Grid>
          {data &&
            data.map((offer) => (
              <Grid item xs={12}>
                <Box sx={{ p: 3, backgroundColor: "#fff" }}>
                  <Typography color={"primary"} variant="h5">
                    {offer.title}
                  </Typography>
                  <Typography variant="h6" color={"secondary"}>
                    {offer.business}
                  </Typography>
                  <Typography color={"text.secondary"}>
                    {parse(offer.description)}
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    <Button color="primary" sx={{ borderRadius: 10 }}>
                      {moment(offer.createdAt).fromNow()}
                    </Button>
                    <IconButton
                      component="a"
                      href={"/offres/" + offer.id}
                      variant="default"
                      sx={{ mx: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      component={"a"}
                      href={"/offres/modifier/" + offer.id}
                      variant="default"
                      sx={{ mx: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(offer.id)}
                      variant="default"
                      sx={{ mx: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Offers;
