import { Grid, MenuItem, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { publishOffer } from "../../redux/actions/offerActions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const mapState = ({ offer }) => ({
  loading: offer.loading,
});

const AddOffer = () => {
  const navigate = useNavigate();
  const [offer, setOffer] = useState({});
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);
  const workTime = ["Temps plein", "Temps partiel"];
  const contrat = ["CDI", "CDD", "Stage", "Volontariat", "Intérim"];

  const handleEditorChange = (value) => {
    setOffer({ ...offer, description: value });
    //onChange(value); // Trigger a callback with the updated content
  };

  const handleChange = (e) =>
    setOffer({ ...offer, [e.target.name]: e.target.value });

  const redirect = () => navigate("/offres");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(publishOffer(offer, redirect));
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Publier une offre d'emploi " />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="poste"
                label="Poste"
                required
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="business"
                label="Entreprise"
                required
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="workTime"
                label="Temps de travail"
                required
                fullWidth
                select
                onChange={handleChange}
              >
                {workTime &&
                  workTime.map((type) => (
                    <MenuItem value={type}>{type} </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="contratType"
                label="Type de contrat"
                required
                fullWidth
                select
                onChange={handleChange}
              >
                {contrat &&
                  contrat.map((type) => (
                    <MenuItem value={type}>{type} </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={offer.description}
                onChange={handleEditorChange}
                style={{ height: 200 }}
              />
            </Grid>

            <Grid item xs={4} md={8}></Grid>
            <Grid item sx={{ marginTop: 5 }} md={4}>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                fullWidth
                sx={{ color: "white", borderRadius: 10 }}
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default AddOffer;
