import React from "react";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import LoginForm from "../../components/auth/Form";
import { auth } from "../../firebase";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

function Login() {
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    setAdmin(localStorage.getItem("adminID"));
  }, [admin]);

  if (!admin)
    return (
      <Grid container sx={{ backgroundColor: "#fff" }} spacing={2}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          sx={{ height: window.innerHeight }}
          xs={12}
        >
          <Paper
            elevation={0}
            sx={{
              p: 5,
              width: 400,
              borderWidth: 1,
              borderColor: "black",
              borderStyle: "solid",
            }}
          >
            <LoginForm />
          </Paper>
        </Grid>
      </Grid>
    );
  return <Navigate to="/tableau-de-bord" replace={true} />;
}

export default Login;
