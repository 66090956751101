import { Grid, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editFormation } from "../../redux/actions/formationActions";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";

const mapState = ({ admin }) => ({
  loading: admin.loading,
});

const EditFormation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);
  const params = useParams();
  const [formation, setFormation] = useState({});

  useEffect(() => {
    const docRef = doc(db, "formations", params.formationID);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setFormation({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const redirect = () => navigate("/formations");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editFormation(params.formationID, data, redirect));
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Modifier la formation " />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Titre"
                placeholder={formation.title}
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                placeholder={formation.description}
                fullWidth
                multiline
                rows={4}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={4} md={8}></Grid>
            <Grid item md={4}>
              <LoadingButton
                loading={loading}
                variant="contained"
                fullWidth
                type="submit"
                sx={{ color: "white", borderRadius: 10 }}
                disableElevation
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default EditFormation;
