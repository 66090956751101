import Applicants from "./views/applicant";
import Login from "./views/auth";
import Business from "./views/business";
import AddBusiness from "./views/business/Add";
import BusinessDetails from "./views/business/Details";
import EditBusiness from "./views/business/Edit";
import Dashboard from "./views/dashboard";
import Formations from "./views/formation";
import AddFormation from "./views/formation/Add";
import FormationDetails from "./views/formation/Details";
import EditFormation from "./views/formation/Edit";
import Offers from "./views/offer";
import AddOffer from "./views/offer/Add";
import OfferDetails from "./views/offer/Details";
import EditOffer from "./views/offer/Edit";

const routes = [
  {
    name: "Dashboard",
    path: "/tableau-de-bord",
    component: <Dashboard />,
  },
  {
    name: "Formation",
    path: "/formations",
    component: <Formations />,
  },
  {
    name: "Formation Details",
    path: "/formations/:formationID",
    component: <FormationDetails />,
  },
  {
    name: "Add Formation",
    path: "/ajouter-une-formation",
    component: <AddFormation />,
  },
  {
    name: "Edit Formation",
    path: "/formations/modifier/:formationID",
    component: <EditFormation />,
  },

  {
    name: "Offer",
    path: "/offres",
    component: <Offers />,
  },
  {
    name: "Offer Details ",
    path: "/offres/:offerID",
    component: <OfferDetails />,
  },
  {
    name: "Edit Offer ",
    path: "/offres/modifier/:offerID",
    component: <EditOffer />,
  },
  {
    name: "Add Offer",
    path: "/ajouter-une-offre",
    component: <AddOffer />,
  },
  {
    name: "Applicant",
    path: "/candidats",
    component: <Applicants />,
  },
  {
    name: "Business",
    path: "/entreprises",
    component: <Business />,
  },
  {
    name: "Business Details",
    path: "/entreprises/:businessID",
    component: <BusinessDetails />,
  },
  {
    name: "Edit Business ",
    path: "/entreprises/modifier/:businessID",
    component: <EditBusiness />,
  },
  {
    name: "AddBusiness",
    path: "/ajouter-une-entreprise",
    component: <AddBusiness />,
  },
];

export default routes;
